import React, {useState} from "react";
import {Button} from "@mui/material";
import Modal from "../modal/mainModal";


export default function Join(){

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }
    return(
        <>
            <Button
                type="submit"
                variant="outlined"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width:200,
                    height:60,
                    marginRight:2
                }}
                size="large"
                onClick={openModal}
            >
                간단한 회원가입
            </Button>
            {modalOpen && <Modal open={modalOpen} close={closeModal} header="회원가입"/>}
        </>
    )

}