import background from "../../../images/bg_yellow_flower_field.jpg";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import {authLogin, companyAdminLogin} from "../../../api";
import Layout from "../../../containers/main/Layout";
import {Desktop, Mobile, Tablet} from "../../../containers/Responsive/responsive";
import {MainContext} from "../../../context";


export default function DefaultRegister(){

    const theme = createTheme();

    const [authId,setAuthId] =useState('')
    const [password, setPassword] = useState('')
    const [res, setRes] = useState('')
    const { toggleClose } = useContext(MainContext)

    const onChangeId = (e)=>{
        setAuthId(e.target.value)
    }
    const onChangePassword = (e)=>{
        setPassword(e.target.value)
    }

    const onSubmitHandler = (e)=>{
        e.preventDefault()

        let data = {authId: authId, password: password}

        setRes(data)

        companyAdminLogin(data)
            .then(res => {
                setRes(res.data)
                window.location.replace('/agreeRegister')
            })
            .catch(function (err) {
                alert(JSON.stringify(err.response.data))
            })

    }

    return(
        <Layout>
            <Desktop>
                <div style={{
                    backgroundImage: `url(${background})`,
                    width:'100%',height:'100%'}} onClick={toggleClose}>
                    <ThemeProvider theme={theme}>
                        <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    height: '600px',
                                    width: '500px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    marginBottom:10

                                }}>
                                <img alt="No Images" src="images/img_splash_title.png"
                                     style={{
                                         display:'flex',
                                         alignItems: "center",
                                         width:250,
                                         marginTop:30,
                                         paddingBottom:20
                                     }}/>
                                <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                                    기업 관리자 로그인
                                </Typography>
                                <Divider color="#696969" sx={{height: 2, width: '400px'}}></Divider><br/>
                                <TextField
                                    value={authId}
                                    margin="normal"
                                    required
                                    sx={{width:400}}
                                    label="기업관리자 ID"
                                    name="userid"
                                    autoFocus
                                    onChange={onChangeId}
                                />
                                <TextField
                                    value={password}
                                    margin="normal"
                                    required
                                    sx={{width:400}}
                                    label="기업관리자 Password"
                                    type="password"
                                    onChange={onChangePassword}
                                />
                                <Button
                                    onClick={onSubmitHandler}
                                    disabled={authId.length<5 || password.length<5}
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 5, mb: 2,
                                        width:400,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    로그인
                                </Button>

                            </Box>
                        </div>
                    </ThemeProvider>
                </div>
            </Desktop>
            <Tablet>
                <div style={{
                    backgroundImage: `url(${background})`,
                    width:'100%',height:'100%',paddingBottom:50}} onClick={toggleClose}>
                    <ThemeProvider theme={theme}>
                        <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    height: '600px',
                                    width: '500px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    marginBottom:10

                                }}>
                                <img alt="No Images" src="images/img_splash_title.png"
                                     style={{
                                         display:'flex',
                                         alignItems: "center",
                                         width:250,
                                         marginTop:30,
                                         paddingBottom:20
                                     }}/>
                                <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                                    기업 관리자 로그인
                                </Typography>
                                <Divider color="#696969" sx={{height: 2, width: '400px'}}></Divider><br/>
                                <TextField
                                    value={authId}
                                    margin="normal"
                                    required
                                    sx={{width:400}}
                                    label="기업관리자 ID"
                                    name="userid"
                                    autoFocus
                                    onChange={onChangeId}
                                />
                                <TextField
                                    value={password}
                                    margin="normal"
                                    required
                                    sx={{width:400}}
                                    label="기업관리자 Password"
                                    type="password"
                                    onChange={onChangePassword}
                                />
                                <Button
                                    onClick={onSubmitHandler}
                                    disabled={authId.length<5 || password.length<5}
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 5, mb: 2,
                                        width:400,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    로그인
                                </Button>

                            </Box>
                        </div>
                    </ThemeProvider>
                </div>
            </Tablet>
            <Mobile>
                <div style={{
                    backgroundImage: `url(${background})`,
                    width:'100%',height:'100%',paddingBottom:20}} onClick={toggleClose}>
                    <ThemeProvider theme={theme}>
                        <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    height: '550px',
                                    width: '350px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    marginBottom:10

                                }}>
                                <img alt="No Images" src="images/img_splash_title.png"
                                     style={{
                                         display:'flex',
                                         alignItems: "center",
                                         width:200,
                                         marginTop:30,
                                         paddingBottom:20
                                     }}/>
                                <Typography component="h3" variant="h8" sx={{paddingBottom:2}}>
                                   기업 관리자 로그인
                                </Typography>
                                <Divider color="#696969" sx={{height: 2, width: '300px'}}></Divider><br/>
                                <TextField
                                    value={authId}
                                    margin="normal"
                                    required
                                    sx={{width:250}}
                                    label="기업관리자 ID"
                                    name="userid"
                                    autoFocus
                                    onChange={onChangeId}
                                />
                                <TextField
                                    value={password}
                                    margin="normal"
                                    required
                                    sx={{width:250}}
                                    label="기업관리자 Password"
                                    type="password"
                                    onChange={onChangePassword}
                                />
                                <Button
                                    onClick={onSubmitHandler}
                                    disabled={authId.length<5 || password.length<5}
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 5, mb: 2,
                                        width:250,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    관리자 로그인
                                </Button>

                            </Box>
                        </div>
                    </ThemeProvider>
                </div>
            </Mobile>

        </Layout>
    )
}