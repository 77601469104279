import {Desktop, Mobile, Tablet} from "../../../containers/Responsive/responsive";
import ContactEmailDesktop from "./Desktop/ContactEmailDesktop";
import ContactEmailTablet from "./Tablet/ContactEmailTablet";
import ContactEmailMobile from "./Mobile/ContactEmailMobile";
import {companyCheck, sendContactMail, signCheck, userInfoCheck} from "../../../api";
import * as React from "react";
import {useEffect, useState} from "react";


export default function ContactEmailService(){

    const [contact,setContact] =useState('')
    const [answer, setAnswer] = useState('')
    const [sendInfo,setSendInfo]= useState('')
    const [company,setCompany]=useState('')
    const [infoContent,setInfoContent]=useState('')


    const [defaultValue,setDefaultValue]=useState('')
    const contactList=['서비스 문의','상품 문의','제품 불량 및 A/S','기타문의']
    const answerList=['Email로 답변받기', 'PhoneSMS로 답변받기']
    const CompanyList = ["Blaubit","LG HelloVision","LG U+","Samsung S1","RAEMIAN", "Samsung C&T", "Coway","Sunil","소속회사 없음"]


    const [sendInfoMessage,setSendInfoMessage]=useState('')


    const [isInfo,setIsInfo]=useState(false)
    const [isInfoContent,setIsInfoContent]=useState(false)
    const [isAnswer,setIsAnswer] = useState(false)
    const [isContact,setIsContact] = useState(false)
    const [isCompany, setIsCompany] = useState(false)
    const [isEmailAnswer,setIsEmailAnswer]=useState(false)
    const [isPhoneAnswer,setIsPhoneAnswer]=useState(false)

    const onChangeContact = (e) =>{
        const currentContact = e.target.value;
        setContact(e.target.value);
        if(currentContact === '서비스 문의'){
            setDefaultValue('해당 서비스 명: ( A타입 / B타입-Single / B타입-Double )\n' +
                '\n문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }else if(currentContact === '상품 문의'){
            setDefaultValue('모델 명: ( DBS-5000-A / DBS-5000-B (Single) / DBS-5000-B (Double) )\n' +
                '\n문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }else if(currentContact === '제품 불량 및 A/S'){
            setDefaultValue('해당 서비스 명: ( A타입 / B타입-Single / B타입-Double )\n' +
                '\n모델 명: ( DBS-5000-A / DBS-5000-B (Single) / DBS-5000-B (Double) )\n' +
                '\n문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }else if(currentContact === '기타문의'){
            setDefaultValue('가입한 기존 고객: ( O / X ) \n' +
                '\n문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }
    };

    const onChangeCompanyContact = (e) =>{
        const currentContact = e.target.value;
        setContact(e.target.value);
        if(currentContact === '서비스 문의'){
            setDefaultValue('해당 서비스 명: ( A타입 / B타입-Single / B타입-Double )\n' +
                '\n문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }else if(currentContact === '상품 문의'){
            setDefaultValue('모델 명: ( DBS-5000-A / DBS-5000-B (Single) / DBS-5000-B (Double) )\n' +
                '\n문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }else if(currentContact === '제품 불량 및 A/S'){
            setDefaultValue('해당 서비스 명: ( A타입 / B타입-Single / B타입-Double )\n' +
                '\n모델 명: ( DBS-5000-A / DBS-5000-B (Single) / DBS-5000-B (Double) )\n' +
                '\n문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }else if(currentContact === '기타문의'){
            setDefaultValue('문의 내용: ')
            setContact(currentContact)
            setIsContact(true)
        }
    };



    const onChangeAnswer =(e)=>{
        const currentAnswer = e.target.value
        setAnswer(currentAnswer)
        if(currentAnswer === 'Email로 답변받기'){
            setAnswer(currentAnswer)
            setIsAnswer(true)
            setIsEmailAnswer(true)
            setIsPhoneAnswer(false)
            if(isUserInfo === true){
                const userIdMap = userData.map(item=>item.userid)
                setUserId(userIdMap[0])
            }

        }else if(currentAnswer === 'PhoneSMS로 답변받기'){
            setAnswer(currentAnswer)
            setIsAnswer(true)
            setIsPhoneAnswer(true)
            setIsEmailAnswer(false)
            if(isUserInfo === true){
                const phoneMap = userData.map(item=>item.phone)
                setPhone(phoneMap[0])
            }
        }

    }


    const onChangeCompany=(e)=>{
        setCompany(e.target.value)
        setIsCompany(true)
    }


    const onChangeSendInfo=(e)=>{
        if(isEmailAnswer === true){
            const currentEmail = e.target.value;
            setSendInfo(currentEmail);
            const emailRegExp =
                /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

            if (!emailRegExp.test(currentEmail)) {
                setSendInfoMessage("올바른 이메일을 작성해주세요.");
                setIsInfo(false);
            } else {
                setSendInfoMessage("");
                setIsInfo(true);
            }
        }else if(isPhoneAnswer === true){
            const currentPhone = e.target.value;
            setSendInfo(currentPhone);
            if (currentPhone.length === 3 || currentPhone.length === 8) {
                setSendInfo(currentPhone + "-");
            }else{
                setSendInfo(currentPhone)
            }
            const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
            if (!phoneRegExp.test(currentPhone)) {
                setSendInfoMessage("하이픈을 포함한 올바른 핸드폰 번호를 입력해 주세요!");
                setIsInfo(false);
            } else {
                setSendInfoMessage("");
                setIsInfo(true);
            }
        }
    }

    const onChangeContent= (e)=>{
        setInfoContent(e.target.value)
        setIsInfoContent(true)
    }


    const [res,setRes]=useState('')

    const onSubmitEmail = (e) => {
        e.preventDefault()
        let data = {
            contact: contact,
            answer: answer,
            sendInfo: userId,
            company: CheckCompany,
            infoContent: infoContent
        }

        sendContactMail(data)
            .then((res) => {
                alert('담당자가 확인 후 30분~1시간 이내 이메일로 답변 드리겠습니다.')
                setRes(res.data)
            })
            .catch(function (err) {
                alert(err.response.data)
            })


    }


    const onSubmitSMS = (e) => {
        e.preventDefault()
        let data = {
            contact: contact,
            answer: answer,
            sendInfo: phone,
            company: CheckCompany,
            infoContent: infoContent
        }

        sendContactMail(data)
            .then((res) => {
                setRes(res.data)
                alert('담당자가 확인 후 30분~1시간 이내 SMS로 답변 드리겠습니다.')
            })
            .catch(function (err) {
                alert(err.response.data)
            })

    }





    const onSubmitHandler = (e)=>{
        e.preventDefault()
        let data = {
            contact:contact,
            answer:answer,
            sendInfo:sendInfo,
            company:company,
            infoContent:infoContent
        }

        if(isPhoneAnswer === true){
            //한번 더 검증
            const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
            if (!phoneRegExp.test(answer)) {
                alert('올바른 전화번호를 입력해주세요.')
                setIsInfo(false)
                setSendInfo('')
            } else {
                sendContactMail(data)
                    .then((res)=>{
                        alert('담당자가 확인 후 30분~1시간 이내 SMS로 답변 드리겠습니다.')
                        setRes(res.data)

                    })
                    .catch(function (err){
                        alert(err.response.data)
                    })
            }
        }else if(isEmailAnswer === true){
            const emailRegExp =
                /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

            if (!emailRegExp.test(answer)) {
                alert("올바른 이메일을 작성해주세요.");
                setIsInfo(false);
                setSendInfo('')

            } else {
                sendContactMail(data)
                    .then((res)=>{
                        setRes(res.data)
                        alert('담당자가 확인 후 30분~1시간 이내 이메일로 답변 드리겠습니다.')
                    })
                    .catch(function (err){

                        alert(err.response.data)
                    })
            }
        }
    }

    //로그인 체크

    const [isLogin, setIsLogin]=useState(false)
    const [user,setUser]=useState({})
    const [CheckCompany, setCheckCompany] = useState('')
    const [userData,setUserData] = useState([])
    const [srcAddress,setSrcAddress] =useState('')
    const [userId,setUserId] = useState('')
    const [phone, setPhone] = useState('')

    const [isUserInfo,setIsUserInfo] = useState(false)

    useEffect(() => {
        try{
            signCheck()
                .then((res)=>{
                    if(res.status === 200) {
                        setIsLogin(true);
                        setUser(res.data)
                        userInfoCheck()
                            .then((res)=>{
                                setUserData(res.data)
                                setIsUserInfo(true)
                            })
                            .catch(function (err){
                            })
                        companyCheck()
                            .then((checkData) => {
                                setCheckCompany(checkData.data)
                                if (checkData.data === 'LG HelloVision') {
                                    setSrcAddress("../../../../images/lghello.png")
                                } else if (checkData.data === 'Samsung S1') {
                                    setSrcAddress("../../../../images/s1.png")
                                } else if (checkData.data === 'LG U+') {
                                    setSrcAddress("../../../../images/lguplus.png")
                                } else if (checkData.data === 'RAEMIAN') {
                                    setSrcAddress("../../../../images/raemian.png")

                                } else if (checkData.data === 'THE WAVE') {
                                    setSrcAddress("../../../../images/the_wave.png")

                                } else if (checkData.data === 'ETCETRA') {
                                    setSrcAddress("../../../../images/etcetra.png")

                                } else if (checkData.data === 'Samsung C&T') {
                                    setSrcAddress("../../../../images/samsung_mulsan.png")

                                } else if (checkData.data === 'Coway') {
                                    setSrcAddress("../../../../images/coway.png")

                                } else if(checkData.data === 'Blaubit') {
                                    //블라우비트
                                    setSrcAddress("../../../../images/blaubit.png")

                                }else if(checkData.data === 'Sunil') {
                                    setSrcAddress("../../../../images/sunil.png")
                                }
                            })
                    }
                })
                .catch((err)=>{
                })

        }catch (err){
        }
    }, [])




    return(
        <>
            <Desktop>
                <ContactEmailDesktop isLogin={isLogin} srcAddress={srcAddress} onChangeContact={onChangeContact}
                                     contact={contact} onChangeCompanyContact={onChangeCompanyContact} contactList={contactList}
                                     answerList={answerList} answer={answer} onChangeAnswer={onChangeAnswer} company={company}
                                     onChangeCompany={onChangeCompany} CompanyList={CompanyList} CheckCompany={CheckCompany}
                                     sendInfo={sendInfo} onChangeSendInfo={onChangeSendInfo} isEmailAnswer={isEmailAnswer}
                                     isInfo={isInfo} userId={userId} phone={phone} defaultValue={defaultValue}
                                     onChangeContent={onChangeContent} onSubmitSMS={onSubmitSMS} onSubmitEmail={onSubmitEmail}
                                     onSubmitHandler={onSubmitHandler} isInfoContent={isInfoContent} isAnswer={isAnswer}
                                     isContact={isContact} isCompany={isCompany}/>
            </Desktop>
            <Tablet>
                <ContactEmailTablet  isLogin={isLogin} srcAddress={srcAddress} onChangeContact={onChangeContact}
                                    contact={contact} onChangeCompanyContact={onChangeCompanyContact} contactList={contactList}
                                    answerList={answerList} answer={answer} onChangeAnswer={onChangeAnswer} company={company}
                                    onChangeCompany={onChangeCompany} CompanyList={CompanyList} CheckCompany={CheckCompany}
                                    sendInfo={sendInfo} onChangeSendInfo={onChangeSendInfo} isEmailAnswer={isEmailAnswer}
                                    isInfo={isInfo} userId={userId} phone={phone} defaultValue={defaultValue}
                                    onChangeContent={onChangeContent} onSubmitSMS={onSubmitSMS} onSubmitEmail={onSubmitEmail}
                                    onSubmitHandler={onSubmitHandler} isInfoContent={isInfoContent} isAnswer={isAnswer}
                                    isContact={isContact} isCompany={isCompany}/>
            </Tablet>
            <Mobile>
                <ContactEmailMobile  isLogin={isLogin} srcAddress={srcAddress} onChangeContact={onChangeContact}
                                    contact={contact} onChangeCompanyContact={onChangeCompanyContact} contactList={contactList}
                                    answerList={answerList} answer={answer} onChangeAnswer={onChangeAnswer} company={company}
                                    onChangeCompany={onChangeCompany} CompanyList={CompanyList} CheckCompany={CheckCompany}
                                    sendInfo={sendInfo} onChangeSendInfo={onChangeSendInfo} isEmailAnswer={isEmailAnswer}
                                    isInfo={isInfo} userId={userId} phone={phone} defaultValue={defaultValue}
                                    onChangeContent={onChangeContent} onSubmitSMS={onSubmitSMS} onSubmitEmail={onSubmitEmail}
                                    onSubmitHandler={onSubmitHandler} isInfoContent={isInfoContent} isAnswer={isAnswer}
                                    isContact={isContact} isCompany={isCompany}/>
            </Mobile>
        </>
    )
}