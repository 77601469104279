import {Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import React from "react";


export default function MobileDataService(props){

    const { CheckCompany, userData, handleChange, contractSortationList, communicationOpenList,
        handleClick}=props


    const nameMap = userData.map(item=>item.name)
    const addrMap = userData.map(item=>item.addr)
    const contract_numMap = userData.map(item=>item.contract_num)
    const device_idMap = userData.map(item=>item.device_id)
    const contract_serviceMap = userData.map(item=>item.contract_service)
    const idMap = userData.map(item=>item.id)
    const telMap = userData.map(item=>item.tel)
    const communicationMap = userData.map(item=>item.communication)
    const service_nameMap = userData.map(item=>item.service_name)
    const service_startMap = userData.map(item=>item.service_start)
    const service_endMap = userData.map(item=>item.service_end)
    const start_upMap = userData.map(item=>item.start_up)




    return(
        <>
            <TextField
                required
                sx={{width:280,paddingBottom:2}}
                size='small'
                type="text"
                name="name"
                label="계약자명"
                defaultValue={nameMap}
                onChange={handleChange}

            />

            <TextField
                required
                sx={{width:280,paddingBottom:2}}
                size='small'
                type="text"
                name="addr"
                label="주소"
                defaultValue={addrMap}
                onChange={handleChange}

            />


            <TextField
                autoFocus
                required
                size='small'
                sx={{width:280,paddingBottom:2}}
                type="text"
                name="contract_num"
                label="계약번호"
                value={contract_numMap}
                onChange={handleChange}
            />
            <TextField
                required
                size='small'
                sx={{width:280,paddingBottom:2}}
                type="text"
                name="device_id"
                label="단말기번호"
                defaultValue={device_idMap}
                onChange={handleChange}
                disabled
            />

            <TextField
                defaultValue={idMap}
                sx={{width:280,paddingBottom:2}}
                required
                size='small'
                type="text"
                name="id"
                label="ID"
                onChange={handleChange}
            />
            <TextField
                defaultValue={telMap}
                required
                sx={{width:280,paddingBottom:1}}
                type="text"
                size='small'
                name="tel"
                label="연락처"
                onChange={handleChange}
            />




            <FormControl sx={{width:280,paddingBottom:2}}>
                <InputLabel>계약자 구분</InputLabel>
                <Select
                    defaultValue={contract_serviceMap}
                    rowsPerPageOptions
                    size='small'
                    name="contract_service"
                    type='text'
                    onChange={handleChange}
                    input={<OutlinedInput label="name" />}
                >
                    {contractSortationList.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            <FormControl sx={{width:280,paddingBottom:2}} >
                <InputLabel>통신</InputLabel>
                <Select
                    defaultValue={communicationMap}
                    rowsPerPageOptions
                    size='small'
                    name="communication"
                    type='text'
                    onChange={handleChange}
                    input={<OutlinedInput label="name" />}
                >
                    {communicationOpenList.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                defaultValue={service_nameMap}
                required
                size='small'
                sx={{width:280,paddingBottom:2}}
                type="text"
                name="service_name"
                label="서비스종류"
                onChange={handleChange}
            />


            <FormControl sx={{width:280,paddingBottom:2}} >
                <InputLabel>개시</InputLabel>
                <Select
                    defaultValue={start_upMap}
                    name="start_up"
                    type='text'
                    size='small'
                    onChange={handleChange}
                    input={<OutlinedInput label="name" />}
                >
                    {communicationOpenList.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>





            <TextField
                defaultValue={service_startMap}
                required
                sx={{width:280,paddingBottom:2}}
                type="text"
                size='small'
                name="service_start"
                label="서비스등록일자"
                onChange={handleChange}
            />


            <TextField
                defaultValue={service_endMap}
                required
                sx={{width:280,paddingBottom:2}}
                fullWidth
                type="text"
                size='small'
                name="service_end"
                label="서비스해지일자"
                onChange={handleChange}
            />



            <Button
                onClick={handleClick}
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 1 ,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width:280}}
                size="large"
            >
                수정하기
            </Button>
        </>
    )
}