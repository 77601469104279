import React from 'react'
import {Button} from "@mui/material";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import {excelDownload} from "../../../../../api";


const TabletExcelDownload = (props)=>{

    const { checkData} = props


    const onDownloadButton = (e) => {
        e.preventDefault()
        excelDownload(checkData)
            .then(res=>{
                const downData = res.data
                console.log(downData)
                const getCurrentTime = ()=>{
                    const m =moment().tz("Asia/Seoul")
                    return m.format("YYYY.MM.DD")
                }
                const ws= XLSX.utils.json_to_sheet(downData);
                const wb= XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb,ws,`DoorBellList`);
                XLSX.writeFile(wb, `DoorBellList ${getCurrentTime()}.xlsx`)

            })
            .catch(err=>{
                alert(err)
                window.location.replace('/table')
            })

    }

    return(
        <Button
            onClick={onDownloadButton}
            variant="outlined"
            type="submit"
            size="medium"
            sx={{
                marginTop:1,
                width:'175px',
                height:'40px',
                border:3,
                "&.MuiButton-root:hover":{
                    color:'#008DDC',
                    backgroundColor:'#c7ebff',
                    borderColor:'#008DDC'
                }
            }}
            disabled={checkData.length < 1}
        >
            Excel Download
        </Button>
    )

}

export default TabletExcelDownload