import * as React from "react";
import {Desktop, Mobile, Tablet} from "../../../../containers/Responsive/responsive";
import {useEffect, useState} from "react";
import {companyCheck} from "../../../../api";


export default function Logo(props){

    const { isLogin, srcAddress, CheckCompany  }=props


    const [isMarginTopsSet, setIsMarginTopSet] =useState(false)

    const [isSunil, setIsSunil] = useState(false)

    useEffect(()=>{
        companyCheck()
            .then(res=>{
                if(res.data === 'LG HelloVision'){
                    setIsMarginTopSet(true)
                }else if(res.data === 'LG U+'){
                    setIsMarginTopSet(true)
                }else if(res.data === 'Blaubit'){
                    setIsMarginTopSet(true)
                }else if(res.data === 'Sunil'){
                    setIsSunil(true)
                }
                else {
                    setIsMarginTopSet(false)
                }
            })

    },[])



    return(
        <>

            {isLogin ? (
                <>
                    <Desktop>
                        <div style={{alignItems: "center", display: "flex", flexDirection: "column",paddingLeft:40}}>
                            {isSunil ? (
                                <>
                                    <img alt="No Images" src={srcAddress}
                                         style={{
                                             marginTop: 30,
                                             width:200
                                         }}/>
                                </>
                            ):(
                                <>
                                    <img alt="No Images" src={srcAddress}
                                         style={{
                                             marginTop: 30
                                         }}/>
                                </>
                            )}


                            <img alt="No Images" src="images/question.png"
                                 style={{
                                     width: 70,
                                     paddingTop: 20
                                 }}/>
                        </div>
                    </Desktop>

                    <Tablet>
                        {isMarginTopsSet ? (
                            <div style={{alignItems: "center", display: "flex", flexDirection: "column",paddingLeft:40}}>
                                {isSunil ? (
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30,
                                                 width:180
                                             }}/>

                                    </>
                                ):(
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30
                                             }}/>
                                    </>
                                )}
                                <img alt="No Images" src="images/question.png"
                                     style={{
                                         width: 70,
                                         paddingTop: 20
                                     }}/>

                            </div>
                        ):(
                            <div style={{alignItems: "center", display: "flex", flexDirection: "column",paddingLeft:40}}>
                                {isSunil ? (
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30,
                                                 width:180
                                             }}/>

                                    </>
                                ):(
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30
                                             }}/>
                                    </>
                                )}

                                <img alt="No Images" src="images/question.png"
                                     style={{
                                         width: 70,
                                         paddingTop: 20
                                     }}/>
                            </div>

                            )}

                    </Tablet>

                    <Mobile>
                        {isMarginTopsSet ? (
                            <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
                                {isSunil ? (
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30,
                                                 width:150
                                             }}/>

                                    </>
                                ):(
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30
                                             }}/>
                                    </>
                                )}

                                <img alt="No Images" src="images/question.png"
                                     style={{
                                         width: 70,
                                         paddingTop: 20
                                     }}/>
                            </div>
                        ):(
                            <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
                                {isSunil ? (
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30,
                                                 width:150
                                             }}/>

                                    </>
                                ):(
                                    <>
                                        <img alt="No Images" src={srcAddress}
                                             style={{
                                                 marginTop: 30
                                             }}/>
                                    </>
                                )}

                                <img alt="No Images" src="images/question.png"
                                     style={{
                                         width: 70,
                                         paddingTop: 20
                                     }}/>
                            </div>
                        )}
                    </Mobile>

                </>

            ):(
                <>
                <Desktop>
                <div style={{alignItems: "center", display: "flex", flexDirection: "column",paddingLeft:40}}>
                    <img alt="No Images" src="images/question.png"
                         style={{
                             width: 100,
                             paddingTop: 30
                         }}/>
                </div>
                </Desktop>

                    <Tablet>
                        <div style={{alignItems: "center", display: "flex", flexDirection: "column",paddingLeft:40}}>
                            <img alt="No Images" src="images/question.png"
                                 style={{
                                     width: 80,
                                     paddingTop: 30
                                 }}/>
                        </div>
                    </Tablet>

                    <Mobile>
                        <div style={{alignItems: "center", display: "flex", flexDirection: "column",paddingLeft:40}}>
                            <img alt="No Images" src="images/question.png"
                                 style={{
                                     width: 60,
                                     paddingTop: 30
                                 }}/>
                        </div>
                    </Mobile>

                </>
                )}

        </>
    )
}