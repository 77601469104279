import {useEffect, useState} from "react";
import background from "../../images/test/jam.png";
import {Box, Button, FormHelperText, Typography} from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import axios from "axios";
import styled from "styled-components";
import {checkQuickToken, infoDate, infoTokenClear, quitTimeInfoToken} from "../api/api";


const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 400px;
  font-weight: 800 !important;
  color: #d32f2f !important;
`;

export default function Quit(){

    const [hour,setHour]=useState('')
    const [minutes,setMinutes] = useState('')
    const [seconds, setSeconds] = useState('')

    const [quitDate,setQuitDate] = useState('')

    const [day,setDay] =useState('')

    let [hMessage,setHMessage]=useState('')
    let [mMessage,setMMessage]=useState('')
    let [sMessage,setSMessage]=useState('')

    let [isHour, setIsHour]=useState(false)
    let [isMinutes, setIsMinutes]=useState(false)
    let [isSeconds, setIsSeconds]=useState(false)

    let check = /^[0-9]+$/;

    const onHour = (e)=>{
        const current = e.target.value;
        setHour(current)
        if(!check.test(current)){
            setHMessage('숫자만 적어야함!!!')
            setIsHour(false)
        }else{
            setHMessage('')
            setIsHour(true)
        }
    }
    const onMinutes = (e)=>{
        const current = e.target.value;
        setMinutes(current)
        if(!check.test(current)){
            setMMessage('숫자만 적어야함!!!')
            setIsMinutes(false)
        }else{
            setMMessage('')
            setIsMinutes(true)
        }
    }

    const onSeconds =(e)=>{
        const current = e.target.value;
        setSeconds(current)
        if(!check.test(current)){
            setSMessage('숫자만 적어야함!!!')
            setIsSeconds(false)
        }else{
            setSMessage('')
            setIsSeconds(true)
        }
    }

    const onClcikHandler = (e)=>{
        e.preventDefault();
        let data
        if(seconds.length < 1){
            data = {
                quit:`${hour}:${minutes}:0`
            }
        }else{
            data = {
                quit:`${hour}:${minutes}:${seconds}`
            }
        }

        quitTimeInfoToken(data)
            .then(res=>{
                window.location.replace('/quitService')
            })
            .catch(err=>{
                alert(err.response.data)
            })


    }
    
    const historyBack = (e)=>{
        e.preventDefault()
        window.location.replace('/quit')
    }
    

    const [clock, setClock] = useState("00시00분00초");
    const [to, setTo] = useState(false)

    useEffect(()=>{
        infoTokenClear()
            .then(res=>{

            })
        infoDate()
            .then((res=>{
                setDay(res.data)
            }))
        checkQuickToken()
            .then(res=>{
                setTo(true)
            })
            .catch(err=>{
                setTo(false)
            })

        const Timer = setInterval(() => {
            let time = new Date();
            let hour
            let filterHour = time.getHours()

            if(filterHour > 12){
                hour = `오후 ${filterHour - 12}`
            }else if(filterHour === 12){
                hour = `오후 ${filterHour}`
            }else{
                hour = `오전 ${filterHour}`
            }

            let m = String(time.getMinutes()).padStart(2,"0")
            let s = String(time.getSeconds()).padStart(2,"0")

            setClock(hour+"시 "+m+"분 "+s+"초");
        },1000);

        return ()=>{
            clearInterval(Timer);
        }



    },[])


    return(
        <>
                <div style={{backgroundImage:`url(${background})`,width:'100%',height:'100%', position:'fixed'}}>
                    <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:100}}>
                        <Box
                            component='form'
                            sx={{
                                backgroundColor:'white',
                                width:500,
                                height:700,
                                border:1,
                                borderRadius:'2rem',
                                margin:20,
                                display:'flex',
                                alignItems:'center',
                                flexDirection:'column',
                                opacity:0.9
                            }}
                        ><br/>
                            {to ? (
                                <>
                                    <img alt="No Images" src="images/test/es.jpg"
                                         style={{
                                             display:'flex',
                                             alignItems: "center",
                                             width:"auto",
                                             height:'300px'
                                         }}/><br/>
                                    <Typography component="h1" variant="h5">
                                        {day}
                                    </Typography><br/>
                                    <Typography component="h1" variant="h5">
                                        {clock}
                                    </Typography><br/>
                                    <Divider color="#696969" sx={{height: 2, width: '400px'}}></Divider><br/>
                                    <div style={{alignItems:'center',display:'flex'}}>
                                        <Typography component="h1" variant="h5" sx={{marginRight:2}}>
                                            출근시간 :
                                        </Typography>
                                        <TextField
                                            value={hour}
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="시간"
                                            autoFocus
                                            onChange={onHour}
                                            sx={{width:80, marginRight:2}}
                                            error={hMessage === '숫자만 적어야함!!!' || false}
                                        />
                                        <TextField
                                            value={minutes}
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="분"
                                            onChange={onMinutes}
                                            sx={{width:80, marginRight:2}}
                                            error={mMessage === '숫자만 적어야함!!!' || false}
                                        />
                                        <TextField
                                            value={seconds}
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="초"
                                            onChange={onSeconds}
                                            sx={{width:80, marginRight:2}}
                                            error={sMessage === '숫자만 적어야함!!!' || false}
                                        /><br/>
                                    </div>
                                    <FormHelperTexts>{hMessage}</FormHelperTexts>
                                    <FormHelperTexts>{mMessage}</FormHelperTexts>
                                    <FormHelperTexts>{sMessage}</FormHelperTexts>
                                    <Typography component="h4" variant="h9" sx={{marginRight:2}}>
                                        초는 기입 안해도 됨!
                                    </Typography>
                                    <br/>
                                    <Button
                                        disabled={isHour === false || isMinutes === false}
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:400,
                                            height:60
                                        }}
                                        size="large"
                                        onClick={onClcikHandler}
                                    >
                                        남은 퇴근시간 확인하기
                                    </Button>
                                </>
                            ):(
                                <>
                                    <h1>아주 잘못된 접근이여</h1>
                                    <h2>짱구랑 만남을 가지고 오도록</h2>
                                    <img alt="No Images" src="images/test/gora.gif"
                                         style={{
                                             display:'flex',
                                             alignItems: "center",
                                             width:"auto",
                                             height:'300px'
                                         }}/><br/><br/>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:400,
                                            height:60
                                        }}
                                        size="large"
                                        onClick={historyBack}
                                    >
                                        짱구랑 만나러 가기
                                    </Button>

                                </>
                                )}

                        </Box>

                    </div>
                </div>


        </>
    )
}