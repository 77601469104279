import React, { useState} from 'react';
import TextField from "@mui/material/TextField";
import {Button, FormHelperText, Typography} from "@mui/material";
import styled from "styled-components";
import {quitJoin} from "../api/api";

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 300px;
  font-weight: 800 !important;
  color: #d32f2f !important;
`;

export default function JoinComponent(){


    const [name, setName] = useState('')
    const [quit, setQuit] = useState('')
    const [quitm, setQuitm] = useState('')
    const [userId,setUserId] =useState('')
    const [pw, setPw] =useState('')

    let [idMessage, setIdMessage]=useState('')
    let [pwMessage, setPwMessage] =useState('')
    let [hMessage,setHMessage]=useState('')
    let [mMessage,setMMessage]=useState('')
    let [nameMessage,setNameMessage]=useState('')

    let [isId,setIsId] = useState(false)
    let [isPw,setIsPw] = useState(false)
    let [isHour, setIsHour]=useState(false)
    let [isMinutes, setIsMinutes]=useState(false)
    let [isName, setIsName] = useState(false)


    const onChangeName = (e)=>{
        const current = e.target.value
        setName(current)
        const nameRegExp = /^[가-힣a-zA-Z]+$/;

        if (!nameRegExp.test(current) || name.length<1) {
            setNameMessage('이름을 두 글자 이상 입력하세요!');
            setIsName(false);
        } else {
            setNameMessage('');
            setIsName(true);
        }
    }

    const quitHour = (e)=>{
        const current = e.target.value
        setQuit(current)
        let checkNum = /^[0-9]+$/;
        if(!checkNum.test(current)){
            setHMessage('숫자만 적어야함!!!')
            setIsHour(false)
        }else{
            setHMessage('')
            setIsHour(true)
        }
    }

    const quitMinutes = (e) =>{
        const current = e.target.value
        setQuitm(current)
        let checkNum = /^[0-9]+$/;
        if(!checkNum.test(current)){
            setMMessage('숫자만 적어야함!!!')
            setIsMinutes(false)
        }else{
            setMMessage('')
            setIsMinutes(true)
        }
    }


    const check = /^[a-z0-9_-]{5,10}$/
    const onChangeId =(e)=>{
        const current = e.target.value
        setUserId(current)

        if(!check.test(current)){
            setIdMessage('아이디는 영문+숫자 5~10자리')
            setIsId(false)
        }else{
            setIdMessage('')
            setIsId(true)
        }
    }

    const onChangePw =(e)=>{
        const current = e.target.value
        setPw(current)

        if(!check.test(current)){
            setPwMessage('비번은 영문+숫자 5~10자리')
            setIsPw(false)
        }else{
            setPwMessage('')
            setIsPw(true)
        }
    }

    const onSubmitHandler =(e)=>{
        e.preventDefault()
        let data ={
                name:name,
                id:userId,
                pw:pw,
                workTime:`${quit}:${quitm}`
            }
        quitJoin(data)
            .then(res=>{
                alert(res.data)
                window.location.reload()
            })
            .catch(err=>{
                console.log(err)
                alert(err.response.data)
            })
    }


    return(
        <>
            <div>
                <form style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                    <img alt="No Images" src="images/test/jam.png"
                         style={{
                             display:'flex',
                             alignItems: "center",
                             width:"auto",
                             height:'150px'
                         }}/><br/>
                    <Typography component="h1" variant="h5">
                    회원가입
                </Typography>

                    <TextField
                    value={name}
                    margin="normal"
                    required
                    fullWidth
                    label="이름"
                    autoFocus
                    onChange={onChangeName}
                    sx={{width:350}}
                    error={nameMessage === '이름을 두 글자 이상 입력하세요!' || false}

                />
                    <FormHelperTexts>{nameMessage}</FormHelperTexts>
                <TextField
                    value={userId}
                    margin="normal"
                    required
                    fullWidth
                    label="아이디"
                    onChange={onChangeId}
                    sx={{width:350}}
                    error={idMessage === '아이디는 영문+숫자 5~10자리' || false}

                />
                    <FormHelperTexts>{idMessage}</FormHelperTexts>
                <TextField
                    value={pw}
                    margin="normal"
                    required
                    type="password"
                    fullWidth
                    label="비번"
                    onChange={onChangePw}
                    sx={{width:350}}
                    error={pwMessage === '비번은 영문+숫자 5~10자리' || false}
                />
                    <FormHelperTexts>{pwMessage}</FormHelperTexts>
                <div>

                    <TextField
                        value={quit}
                        margin="normal"
                        required
                        fullWidth
                        label="일하는 시간(시)"
                        onChange={quitHour}
                        sx={{width:167, marginRight:2}}
                        error={hMessage === '숫자만 적어야함!!!' || false}
                    />
                    <TextField
                        value={quitm}
                        margin="normal"
                        required
                        fullWidth
                        label="일하는 시간(분)"
                        onChange={quitMinutes}
                        sx={{width:167}}
                        error={mMessage === '숫자만 적어야함!!!' || false}
                    />
                </div>
                    <FormHelperTexts>{hMessage}</FormHelperTexts>
                    <FormHelperTexts>{mMessage}</FormHelperTexts>
                    <Typography component="h4" variant="h7">
                        점심시간 포함 해주세용~
                    </Typography><br/>
                <Button
                    onClick={onSubmitHandler}
                    variant="contained"
                    type="submit"
                    size="large"
                    sx={{
                        marginTop:1,
                        width:'350px',
                        height:'50px',
                        border:3,
                        "&.MuiButton-root:hover":{
                            color:'#008DDC',
                            backgroundColor:'#c7ebff',
                            borderColor:'#008DDC'
                        }
                    }}>
                    회원가입
                </Button>
                </form>
            </div>
        </>
    )
}