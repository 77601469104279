import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {companyCheck, signCheck, tableEdit} from "../../../../../../api";
import MustLogin from '../../../../../../modals/contents/RequiredLoginModal'



const UpdateTable = (props) => {

    const { editedData } = props;

    let nameMap = editedData.map(item=>item.name)
    let addrMap = editedData.map(item=>item.addr)
    let contract_numMap = editedData.map(item=>item.contract_num)
    let device_idMap = editedData.map(item=>item.device_id)
    let contract_serviceMap = editedData.map(item=>item.contract_service)
    let idMap = editedData.map(item=>item.id)
    let telMap = editedData.map(item=>item.tel)
    let communicationMap = editedData.map(item=>item.communication)
    let service_nameMap = editedData.map(item=>item.service_name)
    let service_startMap = editedData.map(item=>item.service_start)
    let service_endMap = editedData.map(item=>item.service_end)
    let start_upMap = editedData.map(item=>item.start_up)


    const [inputs, setInputs] = useState({})
    const {contract_num, device_id, name, contract_service, id,
        tel,communication, service_name,service_start,service_end,start_up, addr} =inputs;
    const contractSortationList = ['주계약자','부계약자']
    const communicationOpenList = ['O','X']

    const[res,setRes]=useState('')

    const handleChange = (e)=>{
        e.preventDefault()
        const {value, name} = e.target;
        setInputs({
            ...inputs,[name]:value
        })
    }

    const handleClick = (e)=>{
        e.preventDefault()
        let ids = (id === undefined) ? idMap[0]:id
        let device_ids = (device_id === undefined) ? device_idMap[0]:device_id
        let names = (name === undefined) ? nameMap[0]:name
        let contract_services = (contract_service === undefined) ? contract_serviceMap[0]:contract_service
        let tels = (tel === undefined) ? telMap[0]:tel
        let communications = (communication === undefined) ? communicationMap[0]:communication
        let service_names = (service_name === undefined) ? service_nameMap[0]:service_name
        let service_starts = (service_start === undefined) ? service_startMap[0]:service_start
        let service_ends = (service_end === undefined) ? service_endMap[0]:service_end
        let start_ups = (start_up === undefined) ? start_upMap[0]:start_up
        let addrs = (addr === undefined) ? addrMap[0]:addr

        const data ={
            contract_num:contract_numMap[0],
            device_id:device_ids.toLowerCase(),
            company:CheckCompany,
            name:names,
            contract_service:contract_services,
            id:ids,
            tel:tels,
            communication:communications,
            service_name:service_names,
            service_start:service_starts,
            service_end:service_ends,
            start_up:start_ups,
            addr:addrs
        }

        tableEdit(data)
            .then(res=>{
                setRes(res.data)
                alert('수정 완료')
                window.location.reload()
            })
            .catch(function (err){
                alert(err.response.data)
            })
    }

    const [isCheck, setIsCheck] = useState(false)


    useEffect(()=>{
        let contractMap = editedData.map(item => item.contract_num)
        if (contractMap.length === 1) {
            setIsCheck(true)
        }else{
            alert('2개이상의 수정은 ExcelUpload를 사용해주세요~!')
            setIsCheck(false)
            window.location.reload()
        }
    },[])

    const [CheckCompany, setCheckCompany] = useState('')

    useEffect(() => {
        companyCheck()
            .then((res) => {
                if (res.data === 'LG HelloVision') {
                    setCheckCompany('LG HelloVision')
                }else if(res.data === 'Samsung S1'){
                    setCheckCompany('Samsung S1')
                }else if(res.data === 'LG U+'){
                    setCheckCompany('LG U+')
                }else if(res.data === 'RAEMIAN'){
                    setCheckCompany('RAEMIAN')
                }else if(res.data === 'THE WAVE'){
                    setCheckCompany('THE WAVE')
                }else if(res.data === 'ETCETRA'){
                    setCheckCompany('ETCETRA')
                }else if(res.data === 'Samsung C&T'){
                    setCheckCompany('Samsung C&T')
                }else if(res.data === 'Coway'){
                    setCheckCompany('Coway')
                }else if(res.data === 'Sunil'){
                    setCheckCompany('Sunil')
                }else{
                    //블라우비트
                    setCheckCompany('Blaubit')
                }
            })
    }, [])

    //로그인 체크

    const [isLogin, setIsLogin]=useState(false)
    const [user,setUser]=useState({})

    useEffect(() => {
        try{
            signCheck()
                .then((res)=>{
                    if(res.status === 200){
                        setIsLogin(true);
                        setUser(res.data)
                    }
                })
                .catch((err)=>{

                })

        }catch (err){

        }
    }, [])

    return (
                <>
                    {isLogin ? (
                        <div style={{ marginTop:1, height: 600, width: '100%'}}>
                            <Box
                                sx={{
                                    flexDirection: 'column',
                                    backgroundColor: 'white',
                                    height: '500px',
                                    width: '550px',
                                }}
                            ><br/>
                                <div style={{alignItems:'center',display:'flex',flexDirection: 'column'}}>
                                <Typography component="h4" variant="h0">
                                    계약자 정보수정
                                </Typography><br/>
                                </div>
                                <div style={{alignItems:'center',display:'flex', paddingBottom:15}}>
                                    <TextField
                                        required
                                        sx={{paddingRight:2,width:250}}
                                        type="text"
                                        name="name"
                                        label="계약자명"
                                        autoFocus
                                        defaultValue={nameMap}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        required
                                        sx={{paddingRight:2,width:250}}
                                        type="text"
                                        name="addr"
                                        label="주소"
                                        defaultValue={addrMap}
                                        onChange={handleChange}
                                    />

                                </div>
                                <div style={{alignItems:'center', display:'flex', paddingBottom:15}}>
                                        <TextField
                                            required
                                            sx={{paddingRight:2,width:250}}
                                            type="text"
                                            name="contract_num"
                                            label="계약번호"
                                            value={contract_numMap}
                                            disabled
                                        />
                                        <TextField
                                            required
                                            sx={{paddingRight:2,width:250}}
                                            type="text"
                                            name="device_id"
                                            label="단말기번호"
                                            defaultValue={device_idMap}
                                            onChange={handleChange}
                                        />
                                </div>


                                <div style={{alignItems:'center', display:'flex', paddingBottom:15}}>
                                    <TextField
                                        defaultValue={idMap}
                                        required
                                        sx={{paddingRight:2,width:250}}
                                        type="text"
                                        name="id"
                                        label="ID"
                                        onChange={handleChange}
                                    />

                                    <TextField
                                        defaultValue={telMap}
                                        required
                                        sx={{paddingRight:2,width:250}}
                                        type="text"
                                        name="tel"
                                        label="연락처"
                                        onChange={handleChange}
                                    />



                                </div>


                                <div style={{alignItems:'center', display:'flex', paddingBottom:15}}>

                                    <FormControl sx={{paddingRight:2,width:250}} >
                                        <InputLabel>계약자 구분</InputLabel>
                                        <Select
                                            defaultValue={contract_serviceMap}
                                            rowsPerPageOptions
                                            name="contract_service"
                                            type='text'
                                            onChange={handleChange}
                                            input={<OutlinedInput label="name" />}
                                        >
                                            {contractSortationList.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                        <FormControl sx={{paddingRight:2,width:250}} >
                                            <InputLabel>통신</InputLabel>
                                            <Select
                                                defaultValue={communicationMap}
                                                rowsPerPageOptions
                                                name="communication"
                                                type='text'
                                                onChange={handleChange}
                                                input={<OutlinedInput label="name" />}
                                            >
                                                {communicationOpenList.map((item) => (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                </div>

                                <div style={{alignItems:'center', display:'flex', paddingBottom:15}}>
                                        <TextField
                                            defaultValue={service_nameMap}
                                            required
                                            sx={{paddingRight:2,width:250}}
                                            type="text"
                                            name="service_name"
                                            label="서비스종류"
                                            onChange={handleChange}
                                        />

                                    <FormControl sx={{paddingRight:2,width:250}} >
                                        <InputLabel>개시</InputLabel>
                                        <Select
                                            defaultValue={start_upMap}
                                            name="start_up"
                                            type='text'
                                            onChange={handleChange}
                                            input={<OutlinedInput label="name" />}
                                        >
                                            {communicationOpenList.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </div>

                                <div style={{alignItems:'center', display:'flex', paddingBottom:15}}>
                                    <TextField
                                        defaultValue={service_startMap}
                                        required
                                        sx={{paddingRight:2,width:250}}
                                        type="text"
                                        name="service_start"
                                        label="서비스등록일자"
                                        onChange={handleChange}
                                    />

                                    <TextField
                                            defaultValue={service_endMap}
                                            required
                                            sx={{paddingRight:2,width:250}}
                                            type="text"
                                            name="service_end"
                                            label="서비스해지일자"
                                            onChange={handleChange}
                                        />


                                </div>

                                <div style={{alignItems:'center', display:'flex',paddingRight:30,paddingBottom:5}}>


                                    <Button
                                            onClick={handleClick}
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 2, mb: 1 ,
                                                justifyContent: 'center',
                                                alignItems: 'center' }}
                                            size="large"
                                        >
                                            수정하기
                                        </Button>

                                        <br/>
                                </div>

                                <div style={{alignItems:'center', display:'flex',paddingLeft:80}}>
                                        <Typography component="h4" variant="h0">
                                            (대량으로 추가 시 EXCEL Edit을 이용바랍니다.)
                                        </Typography><br/>
                                </div>


                            </Box>
                        </div>
                    ):(
                        <MustLogin />
                    )}
                </>

    )
}
export default UpdateTable

