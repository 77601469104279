import React, {useState} from 'react'

import background from "../../images/test/jam.png";
import {Box, Button, FormHelperText, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import {quitLogin} from "../api/api";
import styled from "styled-components";
import Join from "./join";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 300px;
  font-weight: 800 !important;
  color: #d32f2f !important;
`;


export default function TestLogin(){


    const [userId,setUserId] =useState('')
    const [pw, setPw] =useState('')

    let [hMessage,setHMessage]=useState('')
    let [mMessage,setMMessage]=useState('')


    let [service, setService]= useState(false)
    let [isHour, setIsHour]=useState(false)
    let [isMinutes, setIsMinutes]=useState(false)


    const onChangeCheck = (e)=>{
        if(e.target.checked){
            setService(true)
        }else{
            setService(false)
        }
    }



    const check = /^[a-z0-9_-]{5,10}$/
    const onChangeId =(e)=>{
        const current = e.target.value
        setUserId(current)

        if(!check.test(current)){
            setHMessage('아이디는 영문+숫자 5~10자리')
            setIsHour(false)
        }else{
            setHMessage('')
            setIsHour(true)
        }
    }

    const onChangePw =(e)=>{
        const current = e.target.value
        setPw(current)

        if(!check.test(current)){
            setMMessage('비번은 영문+숫자 5~10자리')
            setIsMinutes(false)
        }else{
            setMMessage('')
            setIsMinutes(true)
        }
    }

    const onClcikHandler = (e)=>{
        e.preventDefault()

        let data ={
            id:userId,
            pw:pw,
            autoLogin:service
        }

        quitLogin(data)
            .then(res=>{
                window.location.replace('/quit')
            })
    }


    const onClcikHandler3 = (e) =>{
        e.preventDefault()
        window.location.replace('/quit')
    }


    return(
        <>
            <div style={{backgroundImage:`url(${background})`,width:'100%',height:'100%', position:'fixed'}}>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:300}}>
                        <Box
                            sx={{
                                backgroundColor:'white',
                                width:500,
                                height:750,
                                border:1,
                                borderRadius:'2rem',
                                margin:10,
                                display:'flex',
                                alignItems:'center',
                                flexDirection:'column',
                            }}
                        ><br/>
                            <img alt="No Images" src="images/test/purin.gif"
                                 style={{
                                     display:'flex',
                                     alignItems: "center",
                                     width:"auto",
                                     height:'250px'
                                 }}/><br/>
                            <Typography component="h1" variant="h5">
                                로그인 합시다앙
                            </Typography>
                            <form style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                            <TextField
                                value={userId}
                                margin="normal"
                                required
                                fullWidth
                                label="아이디"
                                autoFocus
                                onChange={onChangeId}
                                sx={{width:350}}
                                error={hMessage === '아이디는 영문+숫자 5~10자리' || false}

                            />

                            <TextField
                                value={pw}
                                margin="normal"
                                required
                                type="password"
                                fullWidth
                                label="비번"
                                onChange={onChangePw}
                                sx={{width:350}}
                                error={mMessage === '비번은 영문+숫자 5~10자리' || false}
                            />
                            <FormHelperTexts>{hMessage}</FormHelperTexts>
                            <FormHelperTexts>{mMessage}</FormHelperTexts>
                            <br/>
                            <div>
                            <Button
                                disabled={isHour === false || isMinutes === false}
                                type="submit"
                                variant="outlined"
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width:350,
                                    height:60

                                }}
                                size="large"
                                onClick={onClcikHandler}
                            >
                                로그인
                            </Button>
                            </div>
                            </form>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox color="primary"
                                                       margin="normal"
                                                       required
                                                       fullWidth
                                                       name="service"
                                                       onChange={onChangeCheck}
                                                       checked={service}/>}
                                    label={<Typography variant='body2' >30일 자동로그인</Typography>}
                                />


                            </div>
                            <br/>
                            <div>

                                <Join />

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width:200,
                                        height:60
                                    }}
                                    size="large"
                                    onClick={onClcikHandler3}
                                >
                                    비회원으로 그냥하기
                                </Button>
                            </div>
                        </Box>

                </div>
            </div>
        </>
    )
}