import {DataGrid} from "@mui/x-data-grid";
import { tableFind} from "../../../../../api";
import React,{useEffect} from "react";

const columns = [
    { field: 'company', headerName: '계약회사', width: 120 },
    { field: 'contract_num', headerName: '계약번호', width: 100 },
    { field: 'device_id', headerName: '단말기번호', width: 160 },
    { field: 'start_up', headerName: '개통', width: 60 },
    { field: 'communication', headerName: '통신', width: 60 },
    { field: 'name', headerName: '계약자명', width: 120},
    { field: 'addr', headerName: '주소', width: 100},
    { field: 'contract_service', headerName: '계약자구분', width: 100 },
    { field: 'id', headerName: 'ID', width: 130 },
    { field: 'tel', headerName: '연락처', width: 130 },
    { field: 'service_name', headerName: '서비스종류', width: 130 },
    { field: 'service_start', headerName: '서비스 등록일자', width: 170 },
    { field: 'service_end', headerName: '서비스 해지일자', width: 170 },

];


const DbTable =(props)=>{

    const {CheckCompany, dbData, setDbData, setSelectedData, setEditData, setDownloadData} =props

//
    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => dbData.find((row) => row.id === id ));
        setSelectedData(selectedRowsData)
        setEditData(selectedRowsData)
        setDownloadData(selectedRowsData)
    };


    //(rowData.start_up === true) ? 'O':'X
    const inputData = () =>{
        tableFind()
            .then((res)=>{
                //console.log(res.data)
                const inputData = res.data.map((rowData)=>({
                    company:CheckCompany,//회사명
                    contract_num:rowData.contract_num,//계약번호
                    start_up:rowData.start_up,//개통
                    device_id:rowData.device_id,//MAC
                    name:rowData.name,//이름
                    communication:rowData.communication,//통신
                    id:rowData.id,//유저아이디
                    tel:rowData.tel,//전화번호
                    contract_service:rowData.contract_service,//계약자구분
                    service_name:rowData.service_name,//서비스종류
                    addr:rowData.addr,//주소
                    service_start:rowData.service_start,//서비스시작날짜
                    service_end:rowData.service_end,//서비스종료날짜
                    })
                )
                return setDbData(dbData.concat(inputData))
            })
            .catch((err)=>{
                alert(err.response.data)
            })


    }

    useEffect(inputData,[])

    return(
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={dbData}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            />
        </div>
    )
}
export default DbTable