import axios from 'axios'

const SERVER = process.env.REACT_APP_SERVER
const LOCAL = process.env.REACT_APP_LOCALSERVER


//quitProgram(post)
export const quitMainToken = quitMainTokens => axios.post(`${LOCAL}/qqiiiskwdls/eiwrjsdsdf/wwii/test/fgis`,quitMainTokens,{withCredentials:true})
export const quitTimeInfoToken = quitTimeInfoTokens => axios.post(`${LOCAL}/quit`,quitTimeInfoTokens,{withCredentials:true})
export const quitLogin = quitLogins => axios.post(`${LOCAL}/quitLogin/soakdoas/ewoirewi/2325/33%25%/2lsa`,quitLogins,{withCredentials:true})
export const quitJoin = quitJoins => axios.post(`${LOCAL}/quitJoin/soqwsld/weoir%%23/waskd2%212/qwk`,quitJoins,{withCredentials:true})

//quitProgram(get)
export const tokenAllClear = () => axios.get(`${LOCAL}/quit/token/quickquick/quicktokendata/getclear/this`,{withCredentials:true})
export const infoTokenClear = () => axios.get(`${LOCAL}/quittoken/asia/dtasqda/date/sa/2353/%/test/quit/tokendata/getClear/tokenQuit/quick`,{withCredentials:true})
export const infoDate = () => axios.get(`${LOCAL}/date/asia/seoul/dtasqda/quittest%2352`,{withCredentials:true})
export const checkQuickToken =()=>axios.get(`${LOCAL}/checkQuickQuit`,{withCredentials:true})
export const infoTokenVerify = () => axios.get(`${LOCAL}/quittoken/asia/dtasqda/date/sa/2353/test/quit/tokendata/get`,{withCredentials:true})






