import React, {useContext} from 'react'
import Layout from "../../../containers/main/Layout";
import CustomerComponent from "../component/CustomerComponent";
import {MainContext} from "../../../context";


function Customer(){


    const { toggleClose } = useContext(MainContext)
    return(
       <div onClick={toggleClose}>
           <Layout>
               <CustomerComponent/>
           </Layout>
       </div>
    )
}
export default Customer