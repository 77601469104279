import background from "../../images/test/jam.png";
import * as React from "react";
import {Box, Button} from "@mui/material";

import {useEffect} from "react";
import {quitMainToken, tokenAllClear} from "../api/api";


export default function Main(){

    const onClcikHandler = (e)=>{
        e.preventDefault()

        let data = {
            QuitAccess:'OK'
        }

        quitMainToken(data)
            .then(res=>{
                window.location.replace('/quitTimes')
            })
            .catch(err=>{

            })


    }

    useEffect(()=>{
        tokenAllClear()
            .then(res=>{

            })
            .catch(e=>{

            })
    },[])


    return(
        <>
            <div style={{backgroundImage:`url(${background})`,width:'100%',height:'100%', position:'fixed'}}>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:300}}>
                    <Box
                        component='form'
                        sx={{
                            backgroundColor:'white',
                            width:500,
                            height:500,
                            border:1,
                            borderRadius:'2rem',
                            margin:20,
                            display:'flex',
                            alignItems:'center',
                            flexDirection:'column',
                        }}
                    ><br/>
                        <img alt="No Images" src="images/test/jjang.png"
                             style={{
                                 display:'flex',
                                 alignItems: "center",
                                 width:"auto",
                                 height:'300px'
                             }}/><br/><br/>
                        <Button
                            type="submit"
                            variant="outlined"
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                width:400,
                                height:60
                            }}
                            size="large"
                            onClick={onClcikHandler}
                        >
                            남은 퇴근시간 계산 해보까?
                        </Button>

                    </Box>
                </div>
            </div>
        </>
    )
}