import {createContext, useState} from "react";

export const MainContext = createContext({})
export const MainProvider = ({children})=>{
    const [toggleMenu, setToggleMenu] = useState(false)
    const [toggleBar, setToggleBar] = useState(true)


    const toggleChange = () => {
        setToggleMenu(!toggleMenu)
        setToggleBar(!toggleBar)
    }
    const toggleClose = (e)=>{
        e.stopPropagation()
        if(toggleMenu === true){
            setToggleMenu(false)
            setToggleBar(true)
        }
    }

    return(
        <MainContext.Provider value={{toggleMenu,toggleBar,toggleChange,toggleClose }}>
            {children}
        </ MainContext.Provider>
    )

}