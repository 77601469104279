import React, {useEffect, useState} from "react";
import {companyCheck, searchDataCheck, signCheck, tableDelete, tableEdit} from "../../../../../api";
import {
    Box,
    Typography
} from "@mui/material";
import Layout from "../../../../../containers/main/Layout";
import background from "../../../../../images/bg_yellow_flower_field.jpg";
import RequiredLogin from "../../../../users/RequiredService/main/RequiredLogin";
import DeleteTableMobileService from "../service/DeleteTableMobileService";
import UpdateTableMobileService from "../service/UpdateTableMobileService";
import MobileDataService from "../service/MobileDataService";
import BackMobileService from "../service/BackMobileService";
import {Desktop, Mobile, Tablet} from "../../../../../containers/Responsive/responsive";
import RequiredMobile from "../../../../users/RequiredService/main/RequiredMobile";
import RequiredMobileData from "../../../../users/RequiredService/main/RequiredMobileData";



export default function MobileDbDataTableComponent(){

    const [userData,setUserData]=useState('')
    const [srcAddress, setSrcAddress] = useState('')
    const [CheckCompany, setCheckCompany] = useState('')





    const [inputs, setInputs] = useState({})
    const {contract_num, device_id, name, contract_service, id,
        tel,communication, service_name,service_start,service_end,start_up, addr} =inputs;
    const contractSortationList = ['주계약자','부계약자']
    const communicationOpenList = ['O','X']
    const [isInput,setIsInput]=useState(false)

    const[res,setRes]=useState('')

    const handleChange = (e)=>{
        e.preventDefault()
        const {value, name} = e.target;
        setInputs({
            ...inputs,[name]:value
        })
        setIsInput(true)
    }



    const handleClick = (e)=>{
        e.preventDefault()
        const nameMap = userData.map(item=>item.name)
        const addrMap = userData.map(item=>item.addr)
        const contract_numMap = userData.map(item=>item.contract_num)
        const device_idMap = userData.map(item=>item.device_id)
        const contract_serviceMap = userData.map(item=>item.contract_service)
        const idMap = userData.map(item=>item.id)
        const telMap = userData.map(item=>item.tel)
        const communicationMap = userData.map(item=>item.communication)
        const service_nameMap = userData.map(item=>item.service_name)
        const service_startMap = userData.map(item=>item.service_start)
        const service_endMap = userData.map(item=>item.service_end)
        const start_upMap = userData.map(item=>item.start_up)


        let ids = (id === undefined) ? idMap[0]:id
        let device_ids = (device_id === undefined) ? device_idMap[0]:device_id
        let names = (name === undefined) ? nameMap[0]:name
        let contract_services = (contract_service === undefined) ? contract_serviceMap[0]:contract_service
        let tels = (tel === undefined) ? telMap[0]:tel
        let communications = (communication === undefined) ? communicationMap[0]:communication
        let service_names = (service_name === undefined) ? service_nameMap[0]:service_name
        let service_starts = (service_start === undefined) ? service_startMap[0]:service_start
        let service_ends = (service_end === undefined) ? service_endMap[0]:service_end
        let start_ups = (start_up === undefined) ? start_upMap[0]:start_up
        let addrs = (addr === undefined) ? addrMap[0]:addr

        const data ={
            contract_num:contract_numMap[0],
            device_id:device_ids.toLowerCase(),
            company:CheckCompany,
            name:names,
            contract_service:contract_services,
            id:ids,
            tel:tels,
            communication:communications,
            service_name:service_names,
            service_start:service_starts,
            service_end:service_ends,
            start_up:start_ups,
            addr:addrs
        }

        tableEdit(data)
            .then(res=>{
                setRes(res.data)
                alert('수정 완료')
                window.location.reload()
            })
            .catch(function (err){
                alert(err.response.data)
            })
    }

    const deleteConfirm = ()=>{
        tableDelete(userData)
            .then((res)=>{
                console.log(res.data)
                window.location.replace('/table')
            })
            .catch(function (err){
                console.log(err)
                alert(JSON.stringify(err.response.data))
            })
    }

    const useConfirm = (message = null, onConfirm, onCancel) => {
        if (!onConfirm || typeof onConfirm !== "function") {
            return;
        }
        if (onCancel && typeof onCancel !== "function") {
            return;
        }

        const confirmAction = () => {
            if (window.confirm(message)) {
                onConfirm();
            } else {
                onCancel();
            }
        };

        return confirmAction;
    };



    const cancelConfirm = () => console.log("취소했습니다.");

    const confirmDelete = useConfirm(
        "해당 데이터를 삭제하시겠습니까?",
        deleteConfirm,
        cancelConfirm
    );



    const onBackClick = (e)=>{
        e.preventDefault()
        window.location.replace('/table')
    }

    const [isSearch,setIsSearch]=useState(false)


    useEffect(()=>{
        try {
            searchDataCheck()
                .then((res)=>{
                    console.log(res.data)
                    setUserData(res.data)
                    setIsSearch(true)
                })
            companyCheck()
                .then((res) => {
                    if (res.data === 'LG HelloVision') {
                        setSrcAddress("../../../../../images/lg_hello.png")
                        setCheckCompany('LG HelloVision')
                    }else if(res.data === 'Samsung S1'){
                        setSrcAddress("../../../../../images/s1.png")
                        setCheckCompany('Samsung S1')
                    }else if(res.data === 'LG U+'){
                        setSrcAddress("../../../../../images/lgu.png")
                        setCheckCompany('LG U+')
                    }else if(res.data === 'RAEMIAN'){
                        setSrcAddress("../../../../../images/raemian.png")
                        setCheckCompany('RAEMIAN')
                    }else if(res.data === 'THE WAVE'){
                        setSrcAddress("../../../../../images/the_wave.png")
                        setCheckCompany('THE WAVE')
                    }else if(res.data === 'ETCETRA'){
                        setSrcAddress("../../../../../images/etcetra.png")
                        setCheckCompany('ETCETRA')
                    }else if(res.data === 'Samsung C&T'){
                        setSrcAddress("../../../../../images/samsung_mulsan.png")
                        setCheckCompany('Samsung C&T')
                    }else if(res.data === 'Coway'){
                        setSrcAddress("../../../../../images/coway.png")
                        setCheckCompany('Coway')
                    }else if(res.data === 'Sunil'){
                        setSrcAddress("../../../../../images/sunil.png")
                        setCheckCompany('Sunil')
                    }else{
                        //블라우비트
                        setSrcAddress("../../../../../images/new_blaubit.png")
                        setCheckCompany('Blaubit')
                    }
                })
        }catch (err){
        }
    },[])

    //로그인 체크
    const [isLogin, setIsLogin]=useState(false)
    const [user,setUser]=useState({})

    useEffect(() => {
        try{
            signCheck()
                .then((res)=>{
                    if(res.status === 200){
                        setIsLogin(true);
                        setUser(res.data);
                    }
                })
                .catch((err)=>{
                })
        }catch (err){
        }
    }, [])





    return(
       <>
           <Layout>
               {isLogin ? (
                   <>
                       {isSearch ? (
                           <>
                               <Desktop>
                                   <RequiredMobile />
                               </Desktop>

                               <Tablet>
                                   <RequiredMobile />
                               </Tablet>
                               <Mobile>
                                   <div style={{
                                       backgroundImage: `url(${background})`,
                                       paddingBottom: 100
                                   }}>
                                       <div style={{
                                           display: 'flex',
                                           flexDirection: "column",
                                           alignItems: "center",
                                       }}>
                                           <Box
                                               sx={{
                                                   marginTop:6,
                                                   flexDirection: 'column',
                                                   alignItems: 'center',
                                                   display: 'flex',
                                                   backgroundColor: 'white',
                                                   height: '1050px',
                                                   width: '350px',
                                                   borderRadius: '2rem'
                                               }}
                                           >
                                               <img alt="No Images" src={srcAddress}
                                                    style={{
                                                        marginTop: 30
                                                    }}/><br/>
                                               <Typography component="h4" variant="h0">
                                                   데이터 수정 및 삭제
                                               </Typography>

                                               <BackMobileService onBackClick={onBackClick}/>
                                               <div>
                                                   <UpdateTableMobileService isInput={isInput} handleClick={handleClick}/>
                                                   <DeleteTableMobileService confirmDelete={confirmDelete}/>
                                               </div>

                                               <MobileDataService CheckCompany={CheckCompany} handleChange={handleChange}
                                                                  userData={userData} contractSortationList={contractSortationList}
                                                                  communicationOpenList={communicationOpenList} handleClick={handleClick}
                                                                  />
                                           </Box>
                                       </div>
                                   </div>
                               </Mobile>
                           </>
                       ):(
                           <>
                               <RequiredMobileData />
                           </>
                       )}
                   </>
               ):(
                   <RequiredLogin />
                   )}

           </Layout>
       </>
    )
}