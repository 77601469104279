import React, {useEffect, useState} from 'react'
import { exclusiveCompanyCheck, signCheck} from "../../../../api";
import AgreeRegisterComponent from "../component/AgreeRegisterComponent";
import Layout from "../../../../containers/main/Layout";


function JoinAgreePage(){

    const [checkList, setCheckList] = useState([]);

    const [srcAddress,setSrcAddress] =useState('')


    useEffect(() => {
        exclusiveCompanyCheck()
            .then((res) => {
                setSrcAddress(`../../../../../../images/${res.data}.png`)
            })
    }, [])



    //로그인 체크

    const [isLogin, setIsLogin]=useState(false)
    const [user,setUser]=useState({})

    useEffect(() => {
        try{
            signCheck()
                .then((res)=>{
                    if(res.status === 200){
                        setIsLogin(true);
                        setUser(res.data)
                    }
                })
                .catch((err)=>{

                })

        }catch (err){

        }
    }, [])


    return(
        <>
            <Layout>
            {isLogin ? (
                window.location.replace('/')
                ):(
                    <AgreeRegisterComponent checkList={checkList} setCheckList={setCheckList}
                                            srcAddress={srcAddress}  />
                )}
            </Layout>
        </>
    )
}

export default JoinAgreePage

