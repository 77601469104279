import React, {useContext} from "react";
import MainPage from "./contents/MainPage";
import background from "../../../images/bg_index.png";
import {MainContext} from "../../../context";



export default function BeforeLoginDesktop(){

    const { toggleClose } = useContext(MainContext)

    return(
        <>
            <div style={{backgroundImage:`url(${background})`,
            width:'100%',height:'100%'}} onClick={toggleClose}>
                <MainPage />
            </div>
        </>
    )
}