import React from 'react'
import {Link} from "@mui/joy";
const LG_HELLO_FORM = process.env.REACT_APP_LGHELLO
const LG_U_FORM = process.env.REACT_APP_LGU
const S1_FORM = process.env.REACT_APP_S1
const SAMSUNG_CNT_FORM = process.env.REACT_APP_SAMSUNGCNT
const COWAY_FORM = process.env.REACT_APP_COWAY
const RAEMIAN_FORM = process.env.REACT_APP_RAEMIAN
const THE_WAVE_FORM = process.env.REACT_APP_THEWAVE
const ETCETRA_FORM = process.env.REACT_APP_ETCETRA
const BLAUBIT_FORM = process.env.REACT_APP_BLAUBIT
const SUNIL_FORM = process.env.REACT_APP_SUNIL




export default function ExcelFormDownload(props){

    const { CheckCompany } = props




    const form = ()=>{
        if(CheckCompany === 'LG HelloVision'){
            window.location.replace(LG_HELLO_FORM)
        }else if (CheckCompany === 'Samsung S1') {
            window.location.replace(S1_FORM)
        } else if (CheckCompany === 'LG U+') {
            window.location.replace(LG_U_FORM)
        } else if (CheckCompany === 'RAEMIAN') {
            window.location.replace(RAEMIAN_FORM)
        } else if (CheckCompany === 'THE WAVE') {
            window.location.replace(THE_WAVE_FORM)
        } else if (CheckCompany === 'ETCETRA') {
            window.location.replace(ETCETRA_FORM)
        } else if (CheckCompany === 'Samsung C&T') {
            window.location.replace(SAMSUNG_CNT_FORM)
        } else if (CheckCompany === 'Coway') {
            window.location.replace(COWAY_FORM)
        } else if (CheckCompany === 'Sunil') {
            window.location.replace(SUNIL_FORM)
        } else {
            //블라우비트
            window.location.replace(BLAUBIT_FORM)
        }
    }

    


    return(
        <>
            <Link href='#' onClick={form} >Excel Upload 양식 다운로드</Link>
        </>
    )
}