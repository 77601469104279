import background from "../images/bg_yellow_flower_field.jpg";
import {Box} from "@mui/material/";
import {Button} from "@mui/material";
import React, {useRef, useState} from "react";
import AWS from 'aws-sdk'
import {aiShelterUpdate} from "../api";


const ClientId = process.env.REACT_APP_CLIENT_ID
const ClientSecret = process.env.REACT_APP_CLIENT_PW
const Bucket_name = process.env.REACT_APP_BUCKET_NAME



export default function Index(){

    const fileInputInfo = useRef(null);
    const [file,setFile]=useState('')

    const s3 = new AWS.S3({
        accessKeyId: ClientId,
        secretAccessKey: ClientSecret,
        region:'ap-northeast-2'
    });

    const onUploadButton = (e) =>{
        fileInputInfo.current.click();
    }

    const inputChange = (e)=>{
        const input = e.target;
        const reader = new FileReader();
        reader.onload = function (){
            const fileData = reader.result;
            setFile(fileData)
            let data = {
                data:fileData.toString()
            }
            // aiShelterUpdate(data)
            //     .then(res=>{
            //         alert(res.data)
            //         window.location.reload()
            //     })
            //     .catch(err=>{
            //         alert(err.response.data)
            //         window.location.reload()
            //     })
            const params = {
                Bucket:Bucket_name + '/doorbell',
                Key:'test.zip',
                Body:fileData
            }

            s3.upload(params,function (err,data){
                if(err){
                    alert(err)
                    window.location.reload()
                }else{
                    alert('Doorbell Version Update Successfully')
                    window.location.reload()
                }
            })

        };
        reader.readAsBinaryString(input.files[0]);
    }

    return(
        <>
            <div style={{
                backgroundImage: `url(${background})`,
                paddingBottom: 40
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <Box
                        sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            borderRadius: '2rem'
                        }}
                    >
                        <Button
                            variant="outlined"
                            size="large"
                            sx={{
                                margin:20,
                                width:'300px',
                                height:'100px',
                                border: 3,
                                "&.MuiButton-root:hover":{
                                    color:'#008DDC',
                                    backgroundColor:'#c7ebff',
                                    borderColor:'#008DDC'
                                }
                            }}
                            onClick={onUploadButton}
                        >
                            DoorBell Upload
                        </Button>
                        <input
                            type="file"
                            id="excelFile"
                            ref={fileInputInfo}
                            onChange={inputChange}
                            style={{display:"none"}}/>
                    </Box>
                </div>
            </div>
        </>
    )
}