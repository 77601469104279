import React, {useContext} from "react";
import {Desktop,Tablet, Mobile} from "../../../containers/Responsive/responsive";
import ProductDesktop from "./Desktop/productDesktop";
import ProductTablet from "./Tablet/productTablet";
import ProductMobile from "./Mobile/productMobile";
import {MainContext} from "../../../context";


export default function ProductComponent(){

    const { toggleClose } = useContext(MainContext)

    return(
        <div onClick={toggleClose}>
            <Desktop>
                <ProductDesktop />
            </Desktop>

            <Tablet>
                <ProductTablet />
            </Tablet>

            <Mobile>
                <ProductMobile />
            </Mobile>

        </div>
    )
}