import React from "react"
import { useRoutes } from "react-router-dom"

//일반
import Home from "./pages/Home"

//UserService-main
import Login from "./components/users/Login/main/Login"
import JoinAgree from "./components/users/Register/main/JoinAgree"
import Join from "./components/users/Register/main/Join"
import AdminRegisterLogin from './components/users/Register/index'

//UserService-Find
import FindId from "./components/users/Find/main/FindId/FindId"
import FindPwSelect from "./components/users/Find/main/Find_Pw/FindPwSelect"
import FindPwEmail from "./components/users/Find/main/Find_Pw/FindPwEmail"
import FindPwPhone from "./components/users/Find/main/Find_Pw/FindPwPhone"
import ChangePw from "./components/users/Find/main/Find_Pw/ChangePw"

//UserService-Update
import AuthModify from './components/users/Modify/main/modifyAuth'
import Modify from "./components/users/Modify/main/modify"

//테이블
import Table from "./components/tables/main/Table"
import MobileCreateTable from "./components/tables/event/Mobile/component/CreateTableMobileCompnent"
import MobileDbDataTableComponent from "./components/tables/event/Mobile/component/MobileDbDataTableComponent";

//etc
import Product from "./components/product/main/Product";
import Customer from "./components/customer/main/Customer"
import QuitHome from "./test/component/login"
import QuitMain from './test/main/main'
import Quit from "./test/component/quit"
import QuitService from "./test/service/quitService"
import Version from './update/index'



export default function App() {
  return useRoutes([
      //main
    {path:"/",element:<Home />},

      //UserService-main
    {path:"login",element:<Login />},
    {path:"agreeRegister",element:<JoinAgree />},
    {path:"register",element:<Join/>},
    {path:"AdminRegister",element:<AdminRegisterLogin/>},

      //UserService-Find
    {path:"findId",element:<FindId />},
    {path:"findPwSelect",element:<FindPwSelect />},
    {path:"findPwEmail",element:<FindPwEmail />},
    {path:"findPwPhone",element:<FindPwPhone />},
    {path:"changePw",element:<ChangePw />},

      //UserService-Update
    {path:"profile",element:<Modify />},
    {path:"authModify",element:<AuthModify />},

      //Table
    {path:"table",element:<Table />},

      //Table-Mobile
    {path:"mobileCreateTable",element:<MobileCreateTable />},
    {path:"mobileTable",element:<MobileDbDataTableComponent/>},

      //product-Page
    {path:"product",element:<Product />},

      //Customer-Page
    {path:"customer",element:<Customer />},

      //test
    {path:"quitLogin",element:<QuitHome/>},
    {path:"quit",element:<QuitMain/>},
    {path:"quitTimes",element:<Quit/>},
    {path:"quitService",element:<QuitService/>},

      //version
    {path:"version",element:<Version />}

  ]);
}

