import {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import * as React from "react";
import background from "../../images/test/jam.png";
import Divider from "@mui/material/Divider";
import {checkQuickToken, infoDate, infoTokenVerify} from "../api/api";


export default function QuitService(){

    const [startTime,setStartTime] = useState('')
    const [quitTime,setQuitTime] = useState('')
    const [filterData,setFilterData]= useState('')
    const [day,setDay] =useState('')
    const [clock, setClock] = useState("00시00분00초");
    const [clock2, setClock2] = useState("00시00분00초");
    const [up,setUp] = useState("")
    const [src,setSrc] = useState('')
    const [opac, setOpac] = useState(0)

    const onClcikHandler = (e)=>{
        e.preventDefault();
        window.location.replace('/quitTimes')
    }

    const historyBack = (e)=>{
        e.preventDefault()
        window.location.replace('/quit')
    }

    const [to,setTo]=useState(false)



    useEffect(()=>{
        checkQuickToken()
            .then(res=>{
                setTo(true)
            })
            .catch(err=>{
                setTo(false)
            })

        infoTokenVerify()
            .then(res=>{
                setStartTime(res.data.start)
                setQuitTime(res.data.quit)
            })
        infoDate()
            .then((res=>{
                setDay(res.data)
            }))

        const Timer = setInterval(() => {
            let time = new Date();
            let hour
            let filterHour = time.getHours()

            if(filterHour > 12){
                hour = `오후 ${String(filterHour - 12).padStart(2,"0")}`
            }else if(filterHour === 12){
                hour = `오후 ${filterHour}`
            }else{
                hour = `오전 ${filterHour}`
            }

            let m = String(time.getMinutes()).padStart(2,"0")
            let s = String(time.getSeconds()).padStart(2,"0")

            setClock(hour+"시 "+m+"분 "+s+"초");
        },1000);

        const Timer2 = setInterval(() => {

            infoTokenVerify()
                .then(res=>{
                    //console.log(res.data.filter)
                    let now = new Date();
                    let fd = res.data.filter.split(':')
                    let fy = Number(fd[0])
                    let fMon = Number(fd[1])
                    let fday = Number(fd[2])
                    let fh = Number(fd[3])
                    let fm = Number(fd[4])
                    let fs = Number(fd[5])
                    let quit = Date.parse(`${fy}/${fMon}/${fday} ${fh}:${fm}:${fs}`)

                    const diff = quit - now

                    const upDiff = now - quit

                    if(diff > 0 ){
                        if(diff > 300640){
                            const diffDay = Math.floor(diff / (1000*60*60*24))
                            const diffHour =Math.floor(diff / (1000 * 60 * 60));
                            const diffMin = Math.floor(diff / (1000 * 60));
                            const diffSec = Math.floor(diff / 1000);

                            const d =diffDay;
                            const h = String(diffHour - diffDay * 24);
                            const m = String(diffMin - diffHour * 60).padStart(2,"0");
                            const s = String(diffSec - diffMin * 60 +1).padStart(2,"0");

                            setClock2(h+"시 "+m+"분 "+s+"초");
                            setUp("퇴근 남은 시간...:")
                            setSrc('images/test/ggonug.gif')
                            setOpac(0.8)
                        }else{
                            const diffDay = Math.floor(diff / (1000*60*60*24))
                            const diffHour =Math.floor(diff / (1000 * 60 * 60));
                            const diffMin = Math.floor(diff / (1000 * 60));
                            const diffSec = Math.floor(diff / 1000);

                            const d =diffDay;
                            const h = String(diffHour - diffDay * 24);
                            const m = String(diffMin - diffHour * 60).padStart(2,"0");
                            const s = String(diffSec - diffMin * 60 +1).padStart(2,"0");

                            setClock2(h+"시 "+m+"분 "+s+"초");
                            setUp("!!퇴근 남은 시간(5분 내!!):")
                            setSrc('images/test/exit.jpg')
                            setOpac(0.8)
                        }

                    }else{
                        const diffDay = Math.floor(upDiff / (1000*60*60*24))
                        const diffHour =Math.floor(upDiff / (1000 * 60 * 60));
                        const diffMin = Math.floor(upDiff / (1000 * 60));
                        const diffSec = Math.floor(upDiff / 1000);

                        const d =diffDay;
                        const h = String(diffHour - diffDay * 24);
                        const m = String(diffMin - diffHour * 60).padStart(2,"0");
                        const s = String(diffSec - diffMin * 60 ).padStart(2,"0");

                        setClock2(h+"시 "+m+"분 "+s+"초");
                        setUp("★☆!!초과 근무 시간!!☆★:")
                        setSrc('images/test/IMG_3927.gif')
                        setOpac(1)
                    }

                })

        },1000);

        return ()=>{
            clearInterval(Timer);
            clearInterval(Timer2);
        }
    },[])

    return(
        <>
                <div style={{backgroundImage:`url(${background})`,width:'100%',height:'100%', position:'fixed'}}>
                    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: 100}}>
                        <Box
                            component='form'
                            sx={{
                                backgroundColor: 'white',
                                width: 600,
                                height: 700,
                                border: 1,
                                borderRadius: '2rem',
                                margin: 10,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                opacity: {opac}
                            }}
                        ><br/>
                            {to ? (<>
                                    <img alt="No Images" src={src}
                                         style={{
                                             display: 'flex',
                                             alignItems: "center",
                                             width: "auto",
                                             height: '300px',
                                         }}/><br/>
                                    <Typography component="h1" variant="h5">
                                        {day}
                                    </Typography>

                                    <Divider color="#696969" sx={{height: 2, width: '400px'}}></Divider><br/>
                                    <Typography component="h1" variant="h5">
                                        {startTime}
                                    </Typography><br/>

                                    <Typography component="h1" variant="h5">
                                        {quitTime}
                                    </Typography><br/>

                                    <Typography component="h1" variant="h5">
                                        현재시간: {clock}
                                    </Typography><br/>


                                    <Typography component="h1" variant="h5">
                                        {up} {clock2}
                                    </Typography><br/>




                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 150,
                                            height: 60
                                        }}
                                        size="large"
                                        onClick={onClcikHandler}
                                    >
                                        뒤로가기
                                    </Button>
                                </>
                            ):(
                                <>
                                    <>
                                        <h1>아주아주 잘못된 접근이여</h1>
                                        <h2>짱구랑 만남을 가지고 오도록ㅋ</h2>
                                        <img alt="No Images" src="images/test/picka.gif"
                                             style={{
                                                 display:'flex',
                                                 alignItems: "center",
                                                 width:"auto",
                                                 height:'300px'
                                             }}/><br/><br/>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width:400,
                                                height:60
                                            }}
                                            size="large"
                                            onClick={historyBack}
                                        >
                                            짱구랑 만나러 가기
                                        </Button>

                                    </>
                                </>
                            )}

                        </Box>
                    </div>
                </div>

        </>
    )
}